import styled from 'styled-components';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import logo from '../assets/trademark_logo.svg';

const FooterComponent = styled.div`
  color: ${(props) => props.theme.palette.grey[50]};
  text-align: center;
  padding: 30px 0 50px 0;
  width: 100%;
  
  &>div {
    margin: 20px auto;
    align-items: center;
    width: 30%;
    
    img {
      margin: 0 20px 0 40px;
    }
  }
  
  &>p {
    margin: 0;
    font-size: 0.8em;
      color:white;
      padding: 0 56px 0 56px;
  } 
    &>p>a {
        font-size: 1em;
        color:white;
    }
`;

export default function Footer() {
  const { t } = useTranslation();
  return (
    <FooterComponent>
      <img src={logo} alt="CRIO" width="112px" />
      <br />
      <p>
        {t('Footer.Powered by CRIO')}
        <span>{t('Footer.Version')}</span>
        &nbsp;
        &copy;
        &nbsp;
        {t('Footer.Date')}
            &nbsp;
        {t('Footer.All Rights Reserved')}
        <br />
        <br />
        {t('Footer.Footer Text')}
        <br />
        <a href="https://app.clinicalresearch.io/privacy" target="_blank" rel="noopener noreferrer">
          {t('Footer.Privacy Policy')}
        </a>
      </p>
    </FooterComponent>
  );
}
