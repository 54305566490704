// NOTE: We define a similar enum in
// https://github.com/clinicalresearchio/crio-common-js/blob/main/packages/esource/src/enums/VisitReferenceType.ts
// However, we don't currently reference @crio/common-js-esource in esource-spa
// and esource-spa also duplicates several other enums from that package. If we
// add common-js-esource to esource-spa we should update this, and any other
// referenced enums to those ones and remove the dupes defined in esource-spa
enum VisitReferenceType {
  SPECIFIC = 'SPECIFIC',
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

export default VisitReferenceType;
