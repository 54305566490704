import { CarryForwardType, ProcedureDisplayType, ProcedureType } from '../enums';
import { ProcedureInterface } from '../types';

const getProcedureTypeFromDisplayType = (displayType: ProcedureDisplayType, carryForwardType: CarryForwardType) => {
  switch (displayType) {
    case (ProcedureDisplayType.GRID): {
      return ProcedureType.GRID;
    }

    case (ProcedureDisplayType.NORMAL): {
      return ProcedureType.NORMAL;
    }

    case (ProcedureDisplayType.MULTI_RECORD): {
      return carryForwardType === CarryForwardType.PERMANENT ? ProcedureType.PERMANENT_MULTIRECORD : ProcedureType.MULTIRECORD;
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unrecognized display type: ${displayType}`);
      return ProcedureType.NORMAL;
    }
  }
};

/**
 * Get whether or not the given Procedure has any Reference Questions
 * @param procedure   ProcedureInterface to check
 * @return            boolean that will be true if the given Procedure has any Reference Questions
 */
const hasReferenceQuestions = (procedure: ProcedureInterface): boolean => procedure?.questions.some((question) => !!question.criteria);

export { hasReferenceQuestions, getProcedureTypeFromDisplayType };
